import Vue from "vue";
import { Component } from "vue-property-decorator";
import { FieldClassifications, FieldSources } from "@/enums";
import { fieldRepository, clientRepository, requestRepository } from "@/repositories";
import { RouteNames } from "@/router";
import { IClientDatatable, IFieldForm, IRequestDatatable } from "@/models";
import { ClientDatatable, RequestDatatable } from "@/components";

@Component({
    name: "fieldEdit",
    metaInfo: {
        title: "Update field",
    },
})

@Component({
    components: {
        ClientDatatable, RequestDatatable,
    },
    metaInfo: {
        title: "Fields -> Clients & Requests",
    },
})

export default class FieldEdit extends Vue {

    public loading = false;

    public cantBeDeleted = false;

    public fieldToEdit: IFieldForm = {
        id: null,
        name: "",
        classification: "",
        code: "",
        active: false,
        nameInSourceApp: null,
        source: null,
    }

    public field: IFieldForm = {
        id: null,
        name: "",
        classification: "",
        code: "",
        active: false,
        nameInSourceApp: null,
        source: null,
    }

    public clients: IClientDatatable[] = [];

    public requests: IRequestDatatable[] = [];

    public clientHeaders = [
        { text: "", class: "nowrap", value: "snakeAppLogo", sortable: false },
        { text: "Application", class: "nowrap", cellClass: "nowrap", value: "snakeAppName", sortable: false },
        { text: "AadId", class: "nowrap", cellClass: "nowrap", value: "aadClientId", sortable: false },
        { text: "Status", value: "status", class: "nowrap" },
        { text: "Description", value: "request.description", class: "nowrap", cellClass: "fixedHeight", sortable: false },
        { text: "Requested on", value: "request.requestedOn", class: "nowrap", cellClass: "nowrap" },
        { text: "", value: "actions", sortable: false },
    ];

    public requestHeaders = [
        { text: "", class: "nowrap", value: "snakeAppLogo", sortable: false },
        { text: "Application", class: "nowrap", cellClass: "nowrap", value: "snakeAppName", sortable: false },
        { text: "Status", value: "status", class: "nowrap" },
        { text: "Description", value: "description", class: "nowrap", cellClass: "fixedHeight", sortable: false },
        { text: "Requested on", value: "requestedOn", class: "nowrap", cellClass: "nowrap" },
        { text: "", value: "actions", sortable: false },
    ];

    public FieldClassifications = FieldClassifications

    public FieldSources = FieldSources

    public dialog = false;

    async mounted(): Promise<void> {
        this.loading = true;
        fieldRepository.getOne(this.$route.params.id)
                        .then(res => {
                            this.field = res;
                            this.loading = false;
                            this.reset();
                        }).catch(()=> {
                            this.$router.push({ name: RouteNames.notFound});
                        });

        await clientRepository.getClientsByField(this.$route.params.id)
                        .then(res => {
                            this.clients = res;

                            this.$store.dispatch("app/loadSnakeApps", res.map(r => r.snakeId)).then((snakeApps)=>{
                                this.clients.forEach(ar => {
                                    const snakeApp = snakeApps.find(sa => sa.snakeId === ar.snakeId);
                                    if(snakeApp){
                                        ar.snakeAppName = snakeApp.general.name;
                                        ar.snakeAppLogo = snakeApp.general.logo;
                                        ar.snakeAppIsPlatform = snakeApp.general.isPlatform;
                                    }
                                });
                            });
                            this.loading = false;
                            this.reset();
                            this.cantBeDeleted = this.clients.length > 0;
                        });

        await requestRepository.getActiveRequestsByField(this.$route.params.id)
                        .then(res => {
                            this.requests = res;

                            this.$store.dispatch("app/loadSnakeApps", res.map(r => r.snakeId)).then((snakeApps)=>{
                                this.requests.forEach(ar => {
                                    const snakeApp = snakeApps.find(sa => sa.snakeId === ar.snakeId);
                                    if(snakeApp){
                                        ar.snakeAppName = snakeApp.general.name;
                                        ar.snakeAppLogo = snakeApp.general.logo;
                                        ar.snakeAppIsPlatform = snakeApp.general.isPlatform;
                                    }
                                });
                            });
                            this.cantBeDeleted = this.cantBeDeleted || this.requests.length > 0;
                        });

        let closedRequests: IRequestDatatable[] = [];
        await requestRepository.getClosedRequestsByField(this.$route.params.id)
                        .then(res => {
                            closedRequests = res;
                            this.cantBeDeleted = this.cantBeDeleted || closedRequests.length > 0;
                        });
    }

    async submit(): Promise<void> {
        const action = "updateField";
        await this.$store.dispatch(`app/${action}`, this.fieldToEdit)
        .then(() => {
            this.$emit("success", "Field has been edited successfully !");
            this.field = {...this.fieldToEdit};
            this.$router.push({ name: RouteNames.fieldsList });
        }, error => {
            this.$emit("error", error.response?.data.message);
        });
    }

    public back(): void {
        this.$router.push({ name: RouteNames.fieldsList });
    }

    public reset(): void {
        this.fieldToEdit = {...this.field};
    }

    async handleDelete(id: string): Promise<void>{
        this.dialog = false;
        await fieldRepository.deleteField(id).then(res => {
            if(res !== 1){
                this.$emit("error", "Error while deleting Field " + this.field.name);
            }
            else{
                this.back();
            }
        });
    }
}
