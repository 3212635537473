var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"col":"col-12"}},[_c('v-card',{attrs:{"loading":_vm.loading}},[_c('v-card-title',{staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('div',[_c('v-btn',{staticClass:"ma-2",attrs:{"color":"orange","dark":"","fab":"","small":""}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-border-color ")])],1),_vm._v(" Field edit ")],1),_c('div',[_c('v-btn',{staticClass:"ma-2",attrs:{"color":"red","dark":"","small":""},on:{"click":_vm.back}},[_c('v-icon',[_vm._v(" mdi-keyboard-return ")])],1)],1)]),_c('v-tabs',{attrs:{"align-with-title":""}},[_c('v-tab',[_vm._v(" Field ")]),_c('v-tab-item',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('validation-provider',{attrs:{"name":"name","rules":"required|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"counter":100,"error-messages":errors,"label":"Name","required":"","outlined":""},model:{value:(_vm.fieldToEdit.name),callback:function ($$v) {_vm.$set(_vm.fieldToEdit, "name", $$v)},expression:"fieldToEdit.name"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"code","rules":"required|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"counter":50,"error-messages":errors,"label":"Code","required":"","outlined":""},model:{value:(_vm.fieldToEdit.code),callback:function ($$v) {_vm.$set(_vm.fieldToEdit, "code", $$v)},expression:"fieldToEdit.code"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"classification","rules":"required"}},[_c('v-radio-group',{attrs:{"row":""},model:{value:(_vm.fieldToEdit.classification),callback:function ($$v) {_vm.$set(_vm.fieldToEdit, "classification", $$v)},expression:"fieldToEdit.classification"}},[_c('label',{staticStyle:{"margin-right":"50px"}},[_vm._v("Classification")]),_c('v-radio',{attrs:{"label":"Red","color":"red","value":_vm.FieldClassifications.Red}}),_c('v-radio',{attrs:{"label":"Orange","color":"orange","value":_vm.FieldClassifications.Orange}}),_c('v-radio',{attrs:{"label":"Green","color":"green","value":_vm.FieldClassifications.Green}})],1)],1),_c('validation-provider',{attrs:{"name":"source"}},[_c('v-radio-group',{attrs:{"row":""},model:{value:(_vm.fieldToEdit.source),callback:function ($$v) {_vm.$set(_vm.fieldToEdit, "source", $$v)},expression:"fieldToEdit.source"}},[_c('label',{staticStyle:{"margin-right":"50px"}},[_vm._v("Source")]),_c('v-radio',{attrs:{"label":"Azure Active Directory","value":_vm.FieldSources.AzureActiveDirectory}}),_c('v-radio',{attrs:{"label":"Workday","value":_vm.FieldSources.Workday}}),_c('v-radio',{attrs:{"label":"Azure Active Directory, otherwise Workday","value":_vm.FieldSources.AzureActiveDirectoryOtherwiseWorkday}}),_c('v-radio',{attrs:{"label":"Workday, otherwise Azure Active Directory","value":_vm.FieldSources.WorkdayOtherwiseAzureActiveDirectory}})],1)],1),_c('validation-provider',{attrs:{"name":"nameInSourceApp","rules":"max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"counter":100,"error-messages":errors,"label":"Source Field","outlined":""},model:{value:(_vm.fieldToEdit.nameInSourceApp),callback:function ($$v) {_vm.$set(_vm.fieldToEdit, "nameInSourceApp", $$v)},expression:"fieldToEdit.nameInSourceApp"}})]}}],null,true)}),(!_vm.field.active)?_c('div',[_c('validation-provider',{attrs:{"name":"checkbox"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-checkbox',{attrs:{"error-messages":errors,"label":"Activate this field","type":"checkbox"},model:{value:(_vm.fieldToEdit.active),callback:function ($$v) {_vm.$set(_vm.fieldToEdit, "active", $$v)},expression:"fieldToEdit.active"}})]}}],null,true)})],1):_vm._e(),_c('v-container',[_c('v-row',[_c('v-col',[_c('v-btn',{staticClass:"mr-4",attrs:{"type":"submit","disabled":invalid,"elevation":"3","color":"primary"}},[_vm._v(" submit ")]),_c('v-btn',{attrs:{"elevation":"3"},on:{"click":_vm.reset}},[_vm._v("Reset")])],1),_c('v-col',{attrs:{"align":"right","justify":"space-around"}},[(_vm.fieldToEdit.classification === _vm.FieldClassifications.NewField && _vm.cantBeDeleted === false)?[_c('div',{staticClass:"text-right"},[_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({staticClass:"ml-auto",attrs:{"color":"red","dark":""},on:{"click":function($event){_vm.dialog = true}}},'v-btn',attrs,false),[_vm._v(" Delete ")])]}}],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"},[_vm._v(" Delete confirmation... ")]),_c('v-card-text',{staticClass:"text-center"},[_c('br'),_vm._v("Are you sure you want to delete field "+_vm._s(_vm.fieldToEdit.name)+"? ")]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"red lighten-2","text":""},on:{"click":function($event){return _vm.handleDelete(_vm.fieldToEdit.id)}}},[_vm._v(" Delete ")])],1)],1)],1)],1)]:_vm._e()],2)],1)],1)],1)]}}])})],1)],1),_c('v-tab',[_vm._v(" Clients ")]),_c('v-tab-item',[_c('client-datatable',{attrs:{"loading":_vm.loading,"clients":_vm.clients,"headers":_vm.clientHeaders}})],1),_c('v-tab',[_vm._v(" Requests ")]),_c('v-tab-item',[_c('request-datatable',{attrs:{"loading":_vm.loading,"requests":_vm.requests,"headers":_vm.requestHeaders}})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }